import React, {useState} from 'react';
/** Calling GraphQL */
import { graphql } from "gatsby";
import { DefaultPage } from "../layout/DefaultPage";
import VideoPlayerComponent from '../components/VideoPlayer';
import Dialog from '../components/Dialog';
import VideoGallery from '../components/VideoGallery';

const PageTemplate = ({data: getPageData}) => {
  /** Sinopsis handler*/
  const [sinopsis, setSinopsis] = useState(false);

  const showSinopsis = () => {
    setSinopsis(!sinopsis);
  }

  /** Getting landing data with staticQuery */
  /** Defragmenting data */
  const {frontmatter} = getPageData.getData

  return(
    <DefaultPage socialImg={frontmatter.videoImage.image.childImageSharp.fluid.src} pageTitle={frontmatter.title}>
    <VideoGallery/>
    <Dialog sinopsis={sinopsis} showSinopsis={showSinopsis} videoTitle={frontmatter.title} videoSummary={frontmatter.campaignSummary}/>
    <VideoPlayerComponent videoID={frontmatter.videoData.videoId} showSinopsis={showSinopsis}/>
    </DefaultPage>
  )
}

export const pageQuery = graphql`
  query getPageData($id: String!) {
    getData: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        campaignSummary
        videoData {
          videoId
          videoTitle
        }
        videoImage {
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`;

export default PageTemplate
